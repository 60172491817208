import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Button, Typography, message, theme } from "antd";
import { useAuthContext } from "@/contexts";
import { getReasons, regionsQuery } from "@/features/acts-list";
import queryClient from "@/utils/query-client";
import useUserClass from "@/hooks/use-user-class";
import MessageContent from "@/components/message-content";
import imageIcon from "@/assets/image.svg";
import videoIcon from "@/assets/video.svg";
import { CloudDownloadOutlined } from "@ant-design/icons";
import downloadFile from "@/helpers/download-file";
import getFileData from "@/helpers/get-file-data";
import ReactPlayer from "react-player";
import type { FileType, OffenseState, Status } from "../../types";
import {
  acceptJuridic,
  acceptPerson,
  rejectOffense,
  updateOffenseStatus,
} from "../../api";
import { offenseQuery, statusesQuery } from "../../queries";
import { inspectorsQuery } from "@/features/acts-list/queries";
import { getInspectors } from "@/features/acts-list/api";

const { Title } = Typography;

const initBtns = {
  rejected: { loading: false, disabled: false },
  acceptedIndividual: { loading: false, disabled: false },
  acceptedLegal: { loading: false, disabled: false },
};

const colorMap: Record<Status, string> = {
  accept: "blue",
  under_consideration: "orange",
  rejected: "red",
  overdued: "green",
};

const translationMap: Record<Status, string> = {
  accept: "done",
  under_consideration: "pending",
  rejected: "cancelled",
  overdued: "overdued",
};

const iconMap: Record<FileType, string> = {
  image: imageIcon,
  video: videoIcon,
};

export default function useOffenseState(): OffenseState {
  const [btns, setBtns] = useState(initBtns);

  const [slideIndex, setSlideIndex] = useState({ val: 0 });

  const [note, setNote] = useState("");

  const [reason, setReason] = useState<number>();

  const [region, setRegion] = useState<number>();

  const [district, setDistrict] = useState<number>();

  const [isOpenMapModal, setIsOpenMapModal] = useState(false);

  const [openModalImage, setOpenModalImage] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();

  const { group: userGroup } = useUserClass();

  const navigate = useNavigate();

  const { offenseId } = useParams();

  const { t } = useTranslation();

  const { user } = useAuthContext();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onCloseMapModal = (): void => {
    setIsOpenMapModal(false);
  }

  const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false);

  const handleCarouselModalCancel = (): void => {
    setIsCarouselModalOpen(false);
  };

  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const [isApprovePhycisModalOpen, setIsApprovePhycisModalOpen] =
    useState(false);

  const onRejectionCancel = (): void => {
    setIsRejectionModalOpen(false);
  };

  const onApproveCancel = (): void => {
    setIsApproveModalOpen(false);
  };

  const onApprovePhysicCancel = (): void => {
    setIsApprovePhycisModalOpen(false);
  };

  const renderFile = (file: {
    file: string;
    file_size: number;
    file_type: string;
  }): React.ReactNode => {
    if (
      file.file_type === "image" ||
      (file.file_type === "unknown" && getFileData("ext", file.file) === "jpg")
    ) {
      return (
        <div className="h-[392px] flex flex-col items-center justify-center gap-2">
          <div className="max-w-full max-h-full h-[350px]">
            <img
              src={file?.file}
              alt="offense proof"
              className="max-w-full max-h-full"
            />
          </div>
          <Button
            onClick={() => {
              downloadFile(file.file, getFileData("filename", file.file));
            }}
          >
            {t("download-file")} {getFileData("ext", file.file)}
          </Button>
        </div>
      );
    }

    if (file.file_type === "video") {
      return (
        <ReactPlayer
          width={472}
          height={392}
          url={file.file}
          controls
          style={{
            background: "black",
          }}
        />
      );
    }

    return (
      <div className="h-[392px] flex flex-col justify-center items-center">
        <CloudDownloadOutlined style={{ fontSize: "8rem" }} />
        <Button
          onClick={() => {
            downloadFile(file.file, getFileData("filename", file.file));
          }}
        >
          {t("download-file")} {getFileData("ext", file.file)}
        </Button>
      </div>
    );
  };

  const { data: offense, error: offenseError } = useQuery({
    ...offenseQuery(offenseId as number),
    enabled: Boolean(offenseId),
  });

  const status: Status = offense?.status?.key ?? "";

  const { data: statusesData, error: statusesError } = useQuery(
    statusesQuery(),
  );

  const statuses = statusesData ?? [];

  const regionsData = useQuery({
    ...regionsQuery(),
    select(data) {
      return data.results;
    },
  });

  const regions =
    regionsData.data?.map((r) => ({
      value: r.id,
      label: r.name,
      districts: r.districts,
    })) ?? [];

  const districts =
    regions
      .find((r) => r.value === region)
      ?.districts?.map((d) => ({ value: d.id, label: d.name })) ?? [];

  const reasonsData = useQuery({
    queryKey: ["reasons"],
    queryFn: async () => {
      const res = await getReasons();
      return res;
    },
  });

  const reasons =
    reasonsData.data?.map((r) => ({ value: r.id, label: r.name })) ?? [];

  const statusMutation = useMutation({
    mutationFn: async (payload: {
      id: number;
      data: { status_id: number; is_juridic: boolean | null };
      key: "accepted-legal" | "accepted-individual" | "rejected";
    }) => {
      await updateOffenseStatus(payload.id, payload.data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["offenses"]);
      void queryClient.invalidateQueries(["offense", offenseId as number]);
    },
    onError: () => {
      void messageApi.open({
        key: "mutation-error",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("mutation-error");
            }}
          >
            {t("error")}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
    onMutate: (vars) => {
      if (vars.key === "accepted-legal") {
        setBtns({
          acceptedIndividual: {
            loading: false,
            disabled: true,
          },
          acceptedLegal: {
            loading: true,
            disabled: false,
          },
          rejected: {
            loading: false,
            disabled: true,
          },
        });
      }

      if (vars.key === "accepted-individual") {
        setBtns({
          acceptedIndividual: {
            loading: true,
            disabled: false,
          },
          acceptedLegal: {
            loading: false,
            disabled: true,
          },
          rejected: {
            loading: false,
            disabled: true,
          },
        });
      }

      if (vars.key === "rejected") {
        setBtns({
          acceptedIndividual: {
            loading: false,
            disabled: true,
          },
          acceptedLegal: {
            loading: false,
            disabled: true,
          },
          rejected: {
            loading: true,
            disabled: false,
          },
        });
      }
    },
    onSettled: () => {
      setBtns(initBtns);
    },
  });

  const acceptPersonMutation = useMutation({
    // mutationFn: acceptPerson,
    mutationFn: async (payload: { id: number; inspector: number }) => {
      await acceptPerson(payload.id, {
        inspector: payload?.inspector,
        region: payload?.region,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["offenses"]);
      void queryClient.invalidateQueries(["offense", offenseId as number]);
      setIsApprovePhycisModalOpen(false);

      messageApi.open({
        key: "mutation-success",
        type: "success",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("mutation-success");
            }}
          >
            {t("success")}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
    onError: () => {
      void messageApi.open({
        key: "mutation-error",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("mutation-error");
            }}
          >
            {t("error")}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
  });

  const acceptJuridicMutation = useMutation({
    mutationFn: async (payload: { id: number; inspector: number }) => {
      await acceptJuridic(payload.id, { inspector: payload.inspector, region: payload.region });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["offenses"]);
      void queryClient.invalidateQueries(["offense", offenseId as number]);

      setIsApproveModalOpen(false);
    },
    onError: () => {
      void messageApi.open({
        key: "mutation-error",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("mutation-error");
            }}
          >
            {t("error")}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
  });

  const rejectOffenseMutation = useMutation({
    mutationFn: async (payload: {
      id: number;
      reason?: number;
      description?: string;
    }) => {
      await rejectOffense(payload.id, {
        reason: payload.reason,
        description: payload.description,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["offenses"]);
      void queryClient.invalidateQueries(["offense", offenseId as number]);

      setIsRejectionModalOpen(false);
    },
    onError: () => {
      void messageApi.open({
        key: "mutation-error",
        type: "error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("mutation-error");
            }}
          >
            {t("error")}
          </MessageContent>
        ),
        duration: 2.5,
      });
    },
  });

  const onImgClick = (index: number): void => {
    setIsCarouselModalOpen(true);
    setSlideIndex({ val: index });
  };

  const goBack = (): void => {
    navigate(-1);
  };

  useEffect(() => {
    if (statusesError !== null) {
      void messageApi.error({
        key: "statuses-error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("statuses-error");
            }}
          >
            {/* @ts-expect-error error type is unknown but it will get Response type and object from axios */}
            {error?.statusText ?? t("error-fetching-data")}
          </MessageContent>
        ),
      });
    }

    if (offenseError !== null) {
      void messageApi.error({
        key: "offense-error",
        content: (
          <MessageContent
            destroy={() => {
              messageApi.destroy("offense-error");
            }}
          >
            {/* @ts-expect-error error type is unknown but it will get Response type and object from axios */}
            {error?.statusText ?? t("error-fetching-data")}
          </MessageContent>
        ),
      });
    }
  }, [offenseError, statusesError, messageApi, t]);

  const [regionPhysic, setRegionPhysic] = useState<any>(null);
  const [inspectorPhysic, setInspectorPhysic] = useState<any>(null);

  useEffect(() => {
    if (regionPhysic) {
      setInspectorPhysic(null);
    }
  }, [regionPhysic]);

  const inspectorsPhysicData = useQuery({
    queryKey: ["inspectors-physic", regionPhysic],
    queryFn: async () => {
      const res = await getInspectors({
        region_id: regionPhysic,
      });
      return res;
    },
    select(data) {
      return data.results;
    },
  });

  const inspectorsPhysic =
    inspectorsPhysicData.data?.map((r) => ({
      value: r.username,
      label: r.first_name + " " + r.last_name,
    })) ?? [];





  const [regionJuridic, setRegionJuridic] = useState<any>(null);
  const [inspectorJuridic, setInspectorJuridic] = useState<any>(null);

  useEffect(() => {
    if (regionJuridic) {
      setInspectorJuridic(null);
    }
  }, [regionJuridic]);

  const inspectorsJuridicData = useQuery({
    queryKey: ["inspectors-juridic", regionJuridic],
    queryFn: async () => {
      const res = await getInspectors({
        region_id: regionJuridic,
      });
      return res;
    },
    select(data) {
      return data.results;
    },
  });

  const inspectorsJuridic =
    inspectorsJuridicData.data?.map((r) => ({
      value: r.username,
      label: r.first_name + " " + r.last_name,
    })) ?? [];

  return {
    Title,
    offense,
    statusMutation,
    statuses,
    btns,
    colorMap,
    translationMap,
    iconMap,
    userGroup,
    slideIndex,
    onImgClick,
    status,
    user,
    t,
    contextHolder,
    renderFile,
    isCarouselModalOpen,
    handleCarouselModalCancel,
    colorBgContainer,
    goBack,
    note,
    setNote,
    reason,
    setReason,
    regions,
    districts,
    region,
    setRegion,
    district,
    setDistrict,
    reasons,
    isRejectionModalOpen,
    onRejectionCancel,
    isApproveModalOpen,
    onApproveCancel,
    isApprovePhycisModalOpen,
    setIsRejectionModalOpen,
    setIsApproveModalOpen,
    acceptPersonMutation,
    acceptJuridicMutation,
    rejectOffenseMutation,
    onApprovePhysicCancel,
    setIsApprovePhycisModalOpen,
    regionPhysic,
    setRegionPhysic,
    inspectorPhysic,
    setInspectorPhysic,
    inspectorsPhysic,
    regionJuridic,
    setRegionJuridic,
    inspectorJuridic,
    setInspectorJuridic,
    inspectorsJuridic,
    isOpenMapModal,
    setIsOpenMapModal,
    onCloseMapModal,
    openModalImage,
    setOpenModalImage,
  };
}
