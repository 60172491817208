/* eslint-disable func-names */
import { Button, Divider } from "antd";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const allActsByStatus = {
  mvd: {
    sent: 943,
    inProcess: 279,
    completed: 182,
  },
  prokuratura: {
    sent: 270,
    inProcess: 294,
    completed: 173,
  },
};

export default function AllActsByStatus({ data }: any): JSX.Element {
  const { t } = useTranslation();

  const mvdDonutChart = {
    chart: {
      type: "pie",
      height: "100%",
    },
    labels: [t("send1"), t("pending1"), t("finished1")],
    series: [
      data?.mvd.send ?? 0,
      data?.mvd.in_proccess ?? 0,
      data?.mvd.done ?? 0,
    ],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              // formatter: function () {
              //   return 20000;
              // },
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  const procuraturaDonutChart = {
    chart: {
      type: "pie",
    },
    labels: [t("send1"), t("pending1"), t("finished1")],
    series: [
      data?.prokuratura.send ?? 0,
      data?.prokuratura.in_proccess ?? 0,
      data?.prokuratura.done ?? 0,
    ],
    colors: ["#37CD7B", "#FF5858", "#FFE071"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: any) {
          return val.toLocaleString();
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 100,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              // formatter: function () {
              //   return 20000;
              // },
            },
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "14px",
              color: undefined,
              offsetY: 5,
              formatter: function (val: any) {
                return val.toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  return (
    <div className="bottom flex items-center justify-between p-[10px] rounded-[12px] h-full">
      <div className="flex flex-col items-center h-full">
        <ReactApexChart
          options={{ ...mvdDonutChart } as any}
          series={mvdDonutChart.series}
          type="donut"
          width="200"
          height="80%"
        />
        <h5 className="mb-2 text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
          {t("mvd")}
        </h5>
      </div>
      <div>
        <h4 className="text-left text-[14px] font-semibold font-Golos text-[#232E3A]">
          {t("col2")}
          <Divider className="m-2 p-0 ml-0 mr-0" />
        </h4>
        <ul className="flex flex-col gap-1">
          <li className="flex items-center justify-between gap-2">
            <span className="text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {mvdDonutChart?.series[0]}
            </span>
            <Button
              className="h-[22px]"
              style={{ backgroundColor: "#37CD7B", color: "#fff" }}
            >
              {t("send11")}
            </Button>
            <span className="text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {procuraturaDonutChart?.series[0]}
            </span>
          </li>
          <li className="flex items-center justify-between gap-2">
            <span className="text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {mvdDonutChart?.series[1]}
            </span>
            <Button
              className="h-[22px] "
              style={{ backgroundColor: "#FF5858", color: "#fff" }}
            >
              {t("jarayonda")}
            </Button>
            <span className="text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {procuraturaDonutChart.series[1]}
            </span>
          </li>
          <li className="flex items-center justify-between gap-2">
            <span className="text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {mvdDonutChart.series[2]}
            </span>
            <Button
              className="h-[22px]"
              style={{ backgroundColor: "#FFE071", color: "#fff" }}
            >
              {t("finished11")}
            </Button>
            <span className="text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
              {procuraturaDonutChart.series[2]}
            </span>
          </li>
        </ul>
      </div>
      <div className="flex flex-col items-center h-full">
        <ReactApexChart
          options={
            {
              ...procuraturaDonutChart,
              labels: procuraturaDonutChart.labels,
            } as any
          }
          series={procuraturaDonutChart.series}
          type="donut"
          width="200"
          height="80%"
        />
        <h5 className="mb-2 text-center text-[14px] font-medium leading-[14px] font-Golos text-[#0A0D2C]">
          {t("prosecutor-office")}
        </h5>
      </div>
    </div>
  );
}
