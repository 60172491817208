/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from "react";
import { sift } from "radash";
import type { CustomRoute } from "@/types";

// ROUTE IMPORTS
import { authRoutesWith } from "@/features/auth";
import { actsListRoutes } from "@/features/acts-list";
import { statsRoutes } from "@/features/stats";
import { notificationRoutes } from "@/features/notification";
import { inspectorsRoutes } from "@/features/inspectors";
import { violationRoutes } from "@/features/offenses";
import { formsRoutes } from "@/features/tablet-forms";
import PdfGen from "@/views/pdf-gen";
import ProfileDelete from "@/views/profile-delete";
import dashboarRoutes from "@/features/dashboard/routes";

// Global Pages
const Root = lazy(() => import("@/views/root"));
const NotFound = lazy(() => import("@/views/not-found"));
const Error = lazy(() => import("@/views/error"));

const routes: CustomRoute[] = [
  {
    id: "root",
    title: "E-Dalolatnoma",
    path: "/",
    element: <Root getRoutes={() => routes} />,
    errorElement: <Error getRoutes={() => routes} />,
    children: sift([
      // ROUTES
      dashboarRoutes,
      // statsRoutes,
      actsListRoutes,
      notificationRoutes,
      inspectorsRoutes,
      violationRoutes,
      formsRoutes,
    ]),
  },
  authRoutesWith(() => routes),
  {
    id: "pdf-gen",
    title: "pdf-gen",
    path: "pdf-gen",
    element: <PdfGen />,
  },
  {
    id: "profile-delete",
    title: "profile-delete",
    path: "profile_delete",
    element: <ProfileDelete />,
  },
  {
    id: "global-not-found",
    title: "not-found",
    path: "*",
    element: <NotFound getRoutes={() => routes} />,
  },
];

export default routes;
